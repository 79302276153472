import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    alias: '/home',
    name: 'home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },
  {
    path: '/intro',
    name: 'intro',
    component: () => import(/* webpackChunkName: "about" */ '../views/IntroView.vue')
  },
  {
    path: '/why',
    name: 'why',
    component: () => import(/* webpackChunkName: "about" */ '../views/WhyView.vue')
  },
  {
    path: '/how',
    name: 'how',
    component: () => import(/* webpackChunkName: "about" */ '../views/HowView.vue'),
  },
  {
    path: '/how/tech-for-good',
    name: 'how-tfg',
    component: () => import(/* webpackChunkName: "about" */ '../views/TFGView.vue')
  },
  {
    path: '/how/people-centric-design',
    name: 'how-pcd',
    component: () => import(/* webpackChunkName: "about" */ '../views/PCDView.vue')
  },
  {
    path: '/how/movement-strategy',
    name: 'how-move',
    component: () => import(/* webpackChunkName: "about" */ '../views/MoveView.vue')
  },
  {
    path: '/what',
    name: 'what',
    component: () => import(/* webpackChunkName: "about" */ '../views/WhatView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
